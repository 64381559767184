
import request from '../request'


// 反馈分页
export const screenFeedbackList = (params) => {
    return request({
        url: `/blade-party-admin/big/feedback/page`,
        params: params,
        method: 'GET'
    })
};

// 反馈详情
export const screenFeedbackDetail = (params) => {
    return request({
        url: `/blade-party-admin/big/feedback/detail`,
        params: params,
        method: 'GET'
    })
};

// 反馈新增
export const screenFeedbackInsert = (params) => {
    return request({
        url: `/blade-party-admin/big/feedback/save`,
        data: params,
        method: 'POST'
    })
};

// 获取新增反馈的二维码
export const screenfeedbackQrCode = (params) => {
    return request({
        url: `/blade-party-admin/big/feedback/save/qrcode`,
        params: params,
        method: 'GET'
    })
};

// 获取许愿的二维码
export const screenTinyWishGetQrCode = (params) => {
    return request({
        url: `/blade-party-admin/big/wish/save/qrcode`,
        params: params,
        method: 'GET'
    })
};

// 微心愿分页
export const screenTinyWishList = (params) => {
    return request({
        url: `/blade-party-admin/big/wish/page`,
        params: params,
        method: 'GET'
    })
};

// 微心愿详情
export const screenTinyWishDetail = (params) => {
    return request({
        url: `/blade-party-admin/big/wish/detail`,
        params: params,
        method: 'GET'
    })
};

// 领取心愿
export const screenTinyWishCatch = (params) => {
    return request({
        url: `/blade-party-admin/big/wish/claim`,
        data: params,
        method: 'POST'
    })
};

// 完成心愿
export const screenTinyWishComplete = (params) => {
    return request({
        url: `/blade-party-admin/big/wish/complete`,
        data: params,
        method: 'POST'
    })
};

// 许愿
export const screenTinyWishWish = (params) => {
    return request({
        url: `/blade-party-admin/big/wish/vow`,
        data: params,
        method: 'POST'
    })
};

// 获取实时微心愿
export const getWishIntime = (params) => {
    return request({
        url: `/blade-party-admin/big/wish/has/new`,
        params: params,
        method: 'GET'
    })
};

// 获取实时的反馈 
export const getFeedbackIntime = (params) => {
    return request({
        url: `/blade-party-admin/big/feedback/has/new`,
        params: params,
        method: 'GET'
    })
};